<template>
  <div class="container pt-0">
    <!-- Main Row -->
    <div class="row mt-4">
      <div class="col-md-12 pb-0">
        <h5>Detail Pembayaran Medhop :</h5>
      </div>
      <div class="col-xl-12 col-lg-12 col-md-12 col-12 pt-0">
        <div class="card shadow-sm">
          <div class="card-body">
            <div class="row justify-content-center">
              <div class="col-md-12">
                <span class="h6">Detail Pembayaran :</span>
              </div>

              <div class="col-md-12 mt-2">
                <div class="row no-gutters border border-teal rounded p-2">
                  <div class="col-md-12">
                    <i class="fas fa-file-invoice"></i>
                    <span class="ml-1">PAKET {{paymentData.package_name}}</span>
                  </div>
                </div>
              </div>

              <div class="col-md-12 mt-2" v-if="paymentData.type == 1">
                <div
                  class="row no-gutters border border-teal rounded p-2"
                  style="min-height: 270px"
                >
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-12">
                        <span class="paymentVa">Nomor Virtual Account :</span>
                      </div>

                      <div class="col-12" id="paymentCode">
                        <div class="px-2 row">
                          <input
                            type="text"
                            class="h5 ml-2 border-hide"
                            id="vaNumber"
                            v-model="paymentData.code"
                            disabled
                          />
                          <button
                            type="button"
                            class="btn mb-2 ml-1 copyVaNumber"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Salin No.Virtual Account "
                            @click="copyNumber(paymentData.code)"
                          >
                            <i class="far fa-copy fa-lg text-teal"></i>
                          </button>
                        </div>
                      </div>

                      <div class="col-12">
                        <span class="paymentVa">Nama Virtual Account :</span>
                      </div>

                      <div class="col-12">
                        <span class="vaName h5 paymentName">{{
                          paymentData.name
                        }}</span>
                      </div>

                      <div class="col-12 mt-2">
                        <span>Total Pembayaran :</span>
                      </div>
                      <div class="col-12">
                        <span class="vaAmount h5">{{
                          parseInt(paymentData.amount).toLocaleString("id-ID")
                        }}</span>
                      </div>

                      <div class="col-12 mt-2">
                        <span>Bayar Sebelum :</span>
                      </div>
                      <div class="col-12">
                        <span class="vaEndDate h5 text-danger">{{
                          paymentData.end_date
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="col-12 d-flex justify-content-center">
                      <div
                        style="height: 150px; width: 450px; position: relative"
                        class="text-center"
                      >
                        <img :src="paymentData.url_image" class="image methodImg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 mt-2" v-if="paymentData.type == 2">
                <div
                  class="row no-gutters border border-teal rounded p-2"
                  style="min-height: 270px"
                >
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-12">
                        <span>Nomor Pembayaran :</span>
                      </div>

                      <div class="col-12" id="paymentCode">
                        <div class="px-2 row">
                          <input
                            type="text"
                            class="h5 ml-2 border-hide"
                            id="retailNumber"
                            v-model="paymentData.code"
                            disabled
                          />
                          <button
                            type="button"
                            class="btn mb-2 ml-1 copyVaNumber"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Salin Nomor Pembayaran"
                            @click="copyNumber(paymentData.code)"
                          >
                            <i class="far fa-copy fa-lg text-teal"></i>
                          </button>
                        </div>
                      </div>

                      <div class="col-12">
                        <span class="paymentRetail">Nama Merchant :</span>
                      </div>

                      <div class="col-12">
                        <span class="vaName h5 paymentName">{{
                          paymentData.name
                        }}</span>
                      </div>

                      <div class="col-12 mt-3">
                        <span>Total Pembayaran :</span>
                      </div>
                      <div class="col-12">
                        <span class="vaAmount h5">{{
                          parseInt(paymentData.amount).toLocaleString("id-ID")
                        }}</span>
                      </div>

                      <div class="col-12 mt-3">
                        <span>Bayar Sebelum :</span>
                      </div>
                      <div class="col-12">
                        <span class="vaEndDate h5 text-danger">{{
                          paymentData.end_date
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="col-12 d-flex justify-content-center">
                      <div
                        style="height: 250px; width: 450px; position: relative"
                        class="text-center"
                      >
                        <img :src="paymentData.url_image" class="image methodImg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 mt-2" v-if="paymentData.type == 3">
                <div
                  class="row no-gutters border border-teal rounded p-2"
                  style="min-height: 270px"
                >
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-12">
                        <span>Jenis E-Wallet :</span>
                      </div>

                      <div class="col-12">
                        <div
                          style="height: 70px; width: 100px; position: relative"
                          class="text-center"
                        >
                          <img :src="paymentData.url_image" class="image methodImg" />
                        </div>
                      </div>

                      <div class="col-12">
                        <span>Nama Merchant :</span>
                      </div>

                      <div class="col-12">
                        <span class="h5"> WELLMAGIC MEDIA DIGITAL</span>
                      </div>

                      <div class="col-12 mt-3">
                        <span>Total Pembayaran :</span>
                      </div>
                      <div class="col-12">
                        <span class="vaAmount h5">{{
                          paymentData == null
                            ? "-"
                            : parseInt(paymentData.amount).toLocaleString(
                                "id-ID"
                              )
                        }}</span>
                      </div>

                      <div class="col-12 mt-3">
                        <span>Bayar Sebelum :</span>
                      </div>
                      <div class="col-12">
                        <span class="h5 vaEndDate text-danger">{{
                          paymentData == null ? "-" : paymentData.end_date
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="col-12 d-flex justify-content-center">
                      <span class="hideEwallet"
                        >Scan code QR menggunakan E-wallet anda</span
                      >
                    </div>

                    <div class="col-12 d-flex justify-content-center">
                      <div
                        style="height: 260px; width: 260px; position: relative"
                        class="text-center border border-secondary rounded p-1"
                      >
                        <img
                          :src="
                            'data:image/png;base64,' + paymentData.qr_encode
                          "
                          style="width: 250px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 mt-4">
                <span class="h6">Cara Pembayaran :</span>
                <div
                  class="row no-gutters p-2"
                  v-if="paymentData.type == 1"
                >
                  <div class="col-md-12">
                    <span class="h6 font-weight-bold">
                      Langkah - Langkah Pembayaran Melalui ATM :
                    </span>
                    <!-- <ol type="1" id="Atm" v-for="(data,index) in paymentData.tutorialATM" :key="index">{{data}}</ol> -->
                    <li
                      type="1"
                      v-for="(d, i) in paymentData.tutorialATM"
                      :key="i"
                    >
                      {{ d }}
                    </li>
                  </div>

                  <div class="col-md-12 mt-3">
                    <span class="h6 font-weight-bold">
                      Langkah - Langkah Pembayaran Melalui IBanking :
                    </span>
                    <li
                      type="1"
                      v-for="(d, i) in paymentData.tutorialIBank"
                      :key="i"
                    >
                      {{ d }}
                    </li>
                  </div>

                  <div class="col-md-12 mt-3">
                    <span class="h6 font-weight-bold">
                      Langkah - Langkah Pembayaran Melalui MBanking :
                    </span>
                    <li
                      type="1"
                      v-for="(d, i) in paymentData.tutorialMBank"
                      :key="i"
                    >
                      {{ d }}
                    </li>
                  </div>
                </div>

                <div
                  class="row no-gutters border border-teal rounded p-2"
                  v-if="paymentData.type == 2"
                >
                  <div class="col-md-12">
                    <span class="h6 font-weight-bold">
                      Langkah - Langkah Pembayaran Melalui Alfamart:
                    </span>
                    <!-- <ol type="1" id="alfa"></ol> -->
                    <li
                      type="1"
                      v-for="(d, i) in paymentData.tutorialRetail"
                      :key="i"
                    >
                      {{ d }}
                    </li>
                  </div>
                </div>

                <div
                  class="row no-gutters border border-teal rounded p-2"
                  v-if="paymentData.type == 3"
                >
                  <div class="col-md-12">
                    <span class="h6 font-weight-bold">
                      Langkah - Langkah Pembayaran Melalui E-Wallet :
                    </span>

                    <li
                      type="1"
                      id="ewallet"
                      v-for="(d, i) in paymentData.tutorialEwallet"
                      :key="i"
                    >
                      {{ d }}
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import Form from "@/component/settings/Form.vue";
import module from "@/core/modules/CrudModule.js";
import saasModule from "@/core/modules/CrudSaasModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import { setting, getSetting } from '@/core/services/jwt.service.js'

export default {
  components: {
    Card,
    Form,
  },

  data() {
    return {
      // data
      paymentData: {},
      url_image:"",

      // Other
      dataLoaded: false,
    };
  },

  methods: {
    async get() {
      let data = JSON.parse(localStorage.getItem("payment"));
      let packageData = JSON.parse(localStorage.getItem('paymentDetailData'))
      
      SwalLoading.fire();
      this.paymentData = await saasModule.get(
        "subscribe/get-invoice/" + data.xendit_id
      );

      this.paymentData = this.paymentData.items;
      this.paymentData.url_image = data.url_image
      this.paymentData.package_name = packageData.name.toUpperCase()


      if (this.paymentData) {
        let inter = setInterval(async () => {
          let conf = await saasModule.get("subscribe/check-payment/" + data.xendit_id);

          if (conf == true) {
            clearInterval(inter)
            this.$router.push('/subscribe-confirmation')
          }
        }, 5000);

        Swal.close();
      }
    },

    copyNumber(number) {
      /* Copy the text inside the text field */
      navigator.clipboard.writeText(number);

      Swal.fire({
        title: "Nomor Pembayaran Disalin",
        icon: "success",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      });
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Berlangganan", route: "" },
      { title: "Checkout" },
    ]);
    // Get Data
    this.get();
    
  },
};
</script>

<style >
.image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.image-input-wrapper {
  width: 38px !important;
  height: 38px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.method-card {
  border: 2px solid #dbdbdb !important;
  border-radius: 10px !important;
}

.method-card:hover {
  border: 2px solid #245590 !important;
  background-color: #efefef;
  cursor: pointer;
}

.method-card-selected {
  border: 2px solid #245590 !important;
  background-color: #efefef;
}

.btn-orange {
  background-color: #f47b20 !important;
  color: white;
}
.btn-orange:hover {
  background-color: #f47c20bb !important;
  color: white;
}
</style>